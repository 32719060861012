import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PolygonjsScene = _resolveComponent("PolygonjsScene")!

  return (_openBlock(), _createBlock(_component_PolygonjsScene, {
    sceneName: 'scene_01',
    loadFunction: _ctx.loadFunction,
    autoPlay: true,
    displayLoadingProgressBar: true,
    posterExtension: 'webp',
    baseUrl: "."
  }, null, 8, ["loadFunction"]))
}