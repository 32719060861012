import { vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55bba11c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "polygonjs-scene-container" }
const _hoisted_2 = {
  class: "polygonjs-scene",
  ref: "sceneContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      ref: "posterContainerRef",
      class: _normalizeClass(["poster-container", _ctx.fadeableElementClassObject]),
      style: _normalizeStyle(_ctx.containerStyleObject)
    }, null, 6), [
      [_vShow, _ctx.displayLoadingPoster]
    ]),
    _withDirectives(_createElementVNode("div", {
      ref: "progressBarRef",
      class: _normalizeClass(["progress-bar", _ctx.fadeableElementClassObject])
    }, [
      _createElementVNode("div", {
        class: "progress-bar-bar",
        style: _normalizeStyle(_ctx.progressBarBarStyleObject)
      }, null, 4)
    ], 2), [
      [_vShow, _ctx.displayLoadingProgressBar]
    ]),
    _createElementVNode("div", _hoisted_2, null, 512)
  ]))
}