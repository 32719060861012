
import {
	defineComponent,
	onBeforeUnmount,
	onMounted,
	Ref,
	ref,
	computed,
} from "vue";
import { PolyScene } from "@polygonjs/polygonjs/dist/src/engine/scene/PolyScene";
import { BaseViewerType } from "@polygonjs/polygonjs/dist/src/engine/viewers/_Base";
import { SceneJsonExporterData } from "@polygonjs/polygonjs/dist/src/engine/io/json/export/Scene";
import { PolyEventName } from "@polygonjs/polygonjs/dist/src/engine/poly/utils/PolyEventName";
import { sanitizeUrl } from "@polygonjs/polygonjs/dist/src/core/UrlHelper";

type ConfigureSceneData = (sceneData: SceneJsonExporterData) => void;

interface LoadSceneOptions {
	onProgress: (progress: number) => void;
	domElement: HTMLElement;
	printWarnings: boolean;
	cameraMaskOverride?: string;
	autoPlay: boolean;
	configureSceneData?: ConfigureSceneData;
	sceneDataRoot?: string;
	assetsRoot?: string;
	libsRootPrefix?: string;
}
interface LoadedData {
	scene: PolyScene;
	viewer: BaseViewerType | undefined;
}
type LoadScene = (options: LoadSceneOptions) => Promise<LoadedData>;

const sceneReadyEventName: string =
	PolyEventName.SCENE_READY.toLowerCase().replace("poly", "");
const viewerMountedEventName: string =
	PolyEventName.VIEWER_MOUNTED.toLowerCase().replace("poly", "");
const viewerReadyEventName: string =
	PolyEventName.VIEWER_READY.toLowerCase().replace("poly", "");
const sceneAvailableEventName = "sceneAvailable";

export default defineComponent({
	name: "Polygonjs-Scene",
	emits: [
		sceneReadyEventName,
		viewerMountedEventName,
		viewerReadyEventName,
		sceneAvailableEventName,
		"progress",
	],
	props: {
		sceneName: {
			type: String,
			required: false,
		},
		loadFunction: {
			type: Function,
			required: true,
		},
		configureSceneData: {
			type: Function,
			required: false,
		},
		displayLoadingProgressBar: {
			type: Boolean,
			default: true,
		},
		displayLoadingPoster: {
			type: Boolean,
			default: true,
		},
		posterExtension: {
			type: String,
			default: "png",
		},
		posterUrl: {
			type: String,
		},
		printWarnings: {
			type: Boolean,
			default: false,
		},
		cameraMaskOverride: {
			type: String,
			default: null,
			required: false,
		},
		autoPlay: {
			type: Boolean,
			default: true,
		},
		baseUrl: {
			type: String,
			default: "",
		},
	},
	setup(props, context) {
		const sceneContainer: Ref<HTMLElement | null> = ref(null);
		const posterContainerRef: Ref<HTMLElement | null> = ref(null);
		const progressBarRef: Ref<HTMLElement | null> = ref(null);
		const progress = ref(0);
		let scene: PolyScene | undefined;
		let viewer: BaseViewerType | undefined;

		async function loadScene() {
			if (!sceneContainer.value) {
				console.warn("sceneContainer not created");
				return;
			}

			const loadFunction = props.loadFunction as LoadScene;
			const configureSceneData = props.configureSceneData as
				| ConfigureSceneData
				| undefined;
			const domElement = sceneContainer.value;
			domElement.addEventListener(
				PolyEventName.SCENE_READY,
				(/*event*/) => {
					context.emit(sceneReadyEventName, scene);
				}
			);
			domElement.addEventListener(
				PolyEventName.VIEWER_MOUNTED,
				(event) => {
					viewer = viewer || (event as CustomEvent).detail.viewer;
					if (viewer) {
						context.emit(viewerMountedEventName, viewer);
					}
				}
			);
			domElement.addEventListener(PolyEventName.VIEWER_READY, (event) => {
				viewer = viewer || (event as CustomEvent).detail.viewer;
				if (viewer) {
					context.emit(viewerReadyEventName, viewer);
				}
			});
			const loadedData = await loadFunction({
				onProgress,
				domElement,
				printWarnings: props.printWarnings,
				cameraMaskOverride: props.cameraMaskOverride,
				autoPlay: props.autoPlay,
				configureSceneData,
				sceneDataRoot: `${props.baseUrl}/polygonjs/scenes`,
				assetsRoot: props.baseUrl,
				libsRootPrefix: props.baseUrl,
			});
			scene = loadedData.scene;
			viewer = loadedData.viewer;

			context.emit(sceneAvailableEventName, scene);
		}
		function onProgress(p: number) {
			progress.value = p;
			context.emit("progress", p);
			if (progress.value >= 1) {
				setTimeout(_removePosterAndProgressBar, 1000); // delay should match the css fadeout time
			}
		}
		function _removePosterAndProgressBar() {
			if (posterContainerRef.value) {
				posterContainerRef.value.parentElement?.removeChild(
					posterContainerRef.value
				);
			}
			if (progressBarRef.value) {
				progressBarRef.value.parentElement?.removeChild(
					progressBarRef.value
				);
			}
		}

		function disposeScene() {
			if (scene) {
				scene.dispose();
			}
			if (viewer) {
				viewer.dispose();
			}
		}

		onMounted(loadScene);
		onBeforeUnmount(disposeScene);

		// style and classes
		const fadeableElementClassObject = computed(() => {
			const visible = progress.value > 0 && progress.value < 1;
			return {
				["polygonjs-loader-fadeable"]: true,
				["polygonjs-loader-visible"]: visible,
				["polygonjs-loader-hidden"]: !visible,
			};
		});
		const progressBarBarStyleObject = computed(() => {
			const percent = Math.round(progress.value * 100);
			return {
				width: `${percent}%`,
			};
		});

		// poster

		const posterUrl = computed(() => {
			return sanitizeUrl(
				props.posterUrl ||
					`${props.baseUrl}/polygonjs/screenshots/scenes/${props.sceneName}/poster.${props.posterExtension}`
			);
		});
		const containerStyleObject = computed(() => {
			return {
				backgroundImage: `url('${posterUrl.value}')`,
			};
		});

		return {
			sceneContainer,
			posterContainerRef,
			progressBarRef,
			fadeableElementClassObject,
			progressBarBarStyleObject,
			containerStyleObject,
		};
	},
});
