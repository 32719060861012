
import { defineComponent, computed } from "vue";
import { PolygonjsScene } from "@polygonjs/vue3";
import { fetchSceneAndMount_scene_01 } from "@/polygonjs/scenes/scene_01/autogenerated/fetchSceneAndMount";

export default defineComponent({
  name: "scene-01",

  components: {
    PolygonjsScene,
  },
  setup() {
    const loadFunction = computed(() => fetchSceneAndMount_scene_01);

    return {
      loadFunction,
    };
  },
});
